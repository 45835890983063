@import '~antd/dist/antd.css';

body {
  margin: 0;
  // font-family: Inter, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: Inter, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-dark: #101820;
  --primary-gold: #f7be00;
  --cool-gray: #6b7280;
  --cool-gray-50: #F9FAFB;
  --light-gray: #F8F8F8;
  --positive: #1d8759;
  --dark-blue: #2563eb;
  --negative: #e11d48;
  --cool-gray-400: #9ca3af;
  --disabled-btn: #F3F4F6;
}

h1 , h2 , h3 , h4 , h5 , h6 , p{
  margin-bottom: 0px;
}

.input-error{
  border-color: var(--negative) !important;
}

.input-label-error{
  line-height: 1.3;
  font-size: 14px;
  font-weight: 400;
  color: var(--negative) !important;
}

.ant-btn{
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  &:disabled{
    background-color: var(--disabled-btn) !important;
    color: #9CA3AF !important;
  }

  &:hover , &:focus , &:active{
    color: #FFFFFF;
    border: none;
    box-shadow: none;
    outline: none;
  }

}

.ant-btn-link {
  cursor: pointer;
  color: var(--primary-dark);
}

.anticon-eye-invisible svg{
  width: 15px;
  height: 15px;
}

.bg-gray-50{
  background: #F9FAFB;
  border-radius: 4px;
}

.text-sm{
  font-size: 12px;
}

.text-grey-700{
  color: #374151;
}

.ml-10{
  margin-left: 10px;
}

.p-10{
  padding: 10px;
}

.flex{
  display: flex;
}

.items-center{
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center{
  justify-content: center;
}

.justify-end{
  justify-content: end;
}

.text-left{
  text-align: left;
}

.accountList{
  text-align: left;
  border-radius: 5px;
  margin-bottom: 5px;
  background: #eff2f5;
  border: 2px solid #eff2f5;
}

.ant-collapse > .ant-collapse-item{
  border: none;
}

.ant-collapse-content{
  border: none;
}

.transparent-ant-button{
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  color: #101820;
}

.ant-menu-light .ant-menu-item:hover{
  color: #f7be00;
}

.logOut-text{
  color: #f43f5e;
  width: 100%;
  border-radius: 4px;
}

.logOut-text:hover{
  font-weight: 700;
}

.ant-checkbox-inner{
  width: 20px;
  height: 20px;
}

.ant-checkbox + span{
  font-size: 12px;
  padding-top: 5px;
  padding-right: 0px;
}

.ant-input , .ant-input-affix-wrapper{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-input-affix-wrapper > input.ant-input{
  border: none !important;
  box-shadow: none !important;
}

.pagination-records{
  background: #d3d3d373;
  padding: 5px 10px;
  border-radius: 4px;
}


.btn-success{
  background-color: var(--positive) !important;
  color: white !important;
}

.btn-danger{
  background-color: var(--negative) !important;
  color: white !important;
}

.btn-info{
  background-color: var(--primary-gold) !important;
  color: black !important;
}

#domainClass{
  min-height: 100vh;
  display: flex;
  margin: 20px 0px;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

// Challenge deposit verification CSS
.instruction_box{
  background-color: #f0f0f0;
  p {
    color: #383838c2;
  }
}

#rapyd-toolkit {
  #rapyd-checkout-frame {
    min-height: 554px !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-moz-scrollbar {
  width: 4px;
  height: 4px;
}

.termsText {
  .ant-checkbox + span {
      font-size: 14px;
      padding-top: 8px;
  }
}

.largeFrameModal{
  width: calc(100vw - 100px)!important;
  max-width: 1250px!important;
  height: calc(100vw - 200px)!important;
  max-height: 750px!important;
  min-width: 350px !important;

  .ant-modal-body {
    padding: 0px;
  }
}

.card_type_images {
  height: 32px;
  width: 80px;
  border: 1px solid black;
  cursor: pointer;
}

.alignBoxItems{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addCardBtn {
  span {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: auto;
  }
}

.cardEditForm {
  .ant-form-item-explain-error {
    text-align: left;
  }
}

.credit-section {
  .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse-header {
    box-shadow: 0px 0px 14px #d3d3d333;
  }
}
